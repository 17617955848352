<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="pagination.per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="(value)=>{handlePagination({ per_page: value, page: 1 })}"
            />
            <label>resultados</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="showCollectModal()"
              >
                <span class="text-nowrap">Cobrar comisión manualmente</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="comissions"
        :fields="tableColumns"
        responsive
        primary-key="id"
        show-empty
        empty-text="No existen registros aún"
      >
        <!-- Column: Folio -->
        <template #cell(order)="data">
          <b-link
            :to="{ name: 'order-view', params: { id: data.item.order.id }}"
            class="font-weight-bold"
          >
            #{{ data.item.order.auto_inc_folio }}
          </b-link>
        </template>

        <!-- Column:  -->
        <template #cell(is_charged)="data">
          <b-badge
            pill
            :variant="(data.item.is_charged === true) ? 'success' : 'warning'"
          >
            {{ data.item.is_charged | comissionStatus }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'order-view', params: { id: data.item.order.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Detalles</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            v-if="pagination"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ pagination.per_page }} of {{ pagination.total_objects }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="pagination.page"
              :total-rows="pagination.total_objects"
              :per-page="pagination.per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="(value)=>{handlePagination({ page: value, per_page: pagination.per_page })}"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable,
  BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions } from 'vuex'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      tableColumns: [
        {
          label: 'Proveedor',
          key: 'carrier_name',
          sortable: true,
        },
        {
          label: 'Folio',
          key: 'order',
          sortable: true,
        },
        {
          label: 'Estatus',
          key: 'is_charged',
          sortable: true,
        },
        {
          label: '',
          key: 'actions',
          sortable: true,
        },
      ],
      comissions: [],
      pagination: {},
    }
  },
  beforeMount() {
    const establishmentId = this.userData?.role_name === 'establishment_admin' ? this.userData.scoped_roles[0].role_resource_id : null
    this.fetchEmidaComissionsInstances({ by_establishment: establishmentId })
      .then(response => {
        this.comissions = response.data
        this.pagination = response.meta.pagination
      })
  },
  methods: {
    ...mapActions('emidaComissions', ['fetchEmidaComissionsInstances', 'collectComissionManually']),
    showCollectModal() {
      this.$swal({
        title: '¿Estás seguro de realizar el cobro de manera manual?',
        text: 'Recuerda que las comisiones se cobran de manera automática a las 6am',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '¡Confirmo!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          container: 'dark-layout',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.collectComissionManually()
            .then(() => {
              this.$swal({
                icon: 'success',
                title: '¡Cobrado!',
                text: 'Las comisiones pendientes han sido cobradas',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.fetchEmidaComissionsInstances()
                .then(response => {
                  this.comissions = response.data
                })
            })
        }
      })
    },
    handlePagination({ page, per_page }) {
      this.fetchEmidaComissionsInstances({
        meta: {
          pagination: {
            page,
            per_page,
          },
        },
      })
        .then(response => {
          this.comissions = response.data
          this.pagination = response.meta.pagination
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
